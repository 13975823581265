@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=DM+Serif+Display&family=Darker+Grotesque&family=Roboto+Mono:wght@300;400&display=swap');

html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: #FAF9F6;
    scroll-behavior: smooth;
  }